<template>
	<mt-header :title="title" class="title-header">
		<div slot="left" style="color: #FFFFFF;">
			<mt-button @click="goBack" icon="back"></mt-button>
		</div>
	</mt-header>
</template>

<script>
	import "@/styles/common.css"
	export default {
		name: 'Invite',
		props: {
			title: {
				type: String,
				default: () => {
					return ''
				}
			}
			
		},
		methods: {
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
	.title-header {
		height: 54px; 
		background-color: #009245; 
		position: absolute; top: 0px; 
		z-index: 999;
	}
</style>
